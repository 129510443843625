<template>
  <div class="integrity-policy-page">
    <LoadingSpinner v-if="loading" />

    <template v-else-if="!exception">
      <PageBanner
        :background="content.fields.pageBanner.fields.background"
        :heading="content.fields.pageBanner.fields.heading"
        :subheading="content.fields.pageBanner.fields.subheading"
        class="integrity-policy-page-banner" />
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <RichTextRenderer :document="content.fields.content" />
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import PageBanner from '@/components/views/PageBanner'
import LoadingSpinner from '@/components/LoadingSpinner'
import RichTextRenderer from 'contentful-rich-text-vue-renderer'

import {
  mapActions,
  mapState
} from 'vuex'

export default {
  components: {
    LoadingSpinner,
    PageBanner,
    RichTextRenderer
  },
  computed: {
    ...mapState('IntegrityPolicyPage', [
      'loading',
      'exception',
      'content'
    ])
  },
  created () {
    this.loadContent()
  },
  methods: {
    ...mapActions('IntegrityPolicyPage', ['loadContent'])
  }
}
</script>

<style lang="scss" scoped>
.integrity-policy-page {
  margin-bottom: 50px;

  &-banner {
    margin-bottom: 40px;
  }
}
</style>
